<template>
  <div>
    <v-parallax
      :height="$vuetify.breakpoint.smAndUp ? 400 : 300"
      dark
      :src="parallaxImg"
    >
    </v-parallax>

    <v-container>
      <h1 class="my-8 text-h2 font-weight-medium text-center">Egipto</h1>

      <v-row>
        <v-col
          v-for="(card, i) in cards"
          :key="i"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-hover
            v-slot="{ hover }"
          >
            <v-card
              :class="$vuetify.breakpoint.mdAndDown ? 'mx-auto' : ''"
              color="black"
              :elevation="hover ? 8 : 2"
            >
              <v-img
                height="250"
                :src="card.img"
              ></v-img>

              <v-card-title class="flex justify-space-between align-center">
                <div
                  v-html="$vuetify.lang.t(card.title)"
                  style="width: calc(100% - 36px)"
                ></div>
                <v-btn
                  icon
                >
                  <v-icon size="24px">
                    {{ 'mdi-share-variant' }}
                  </v-icon>
                </v-btn>
              </v-card-title>

              <v-divider class="mx-4 mb-4"></v-divider>

              <v-row no-gutters justify="space-between" class="px-4">
                <v-card-text class="col-auto">
                  <div class="caption">
                    {{ $vuetify.lang.t('$vuetify.card.from') }}
                  </div>

                  <div class="headline">
                    {{ card.price }}€
                  </div>

                  <div class="caption">
                    {{ card.day }} {{ $vuetify.lang.t('$vuetify.card.days') }} /
                    {{ card.night }} {{ $vuetify.lang.t('$vuetify.card.nights') }}
                  </div>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    :to="card.link"
                    tag="a"
                    color="red darken-1"
                  >
                    {{ $vuetify.lang.t('$vuetify.card.more') }}
                  </v-btn>
                </v-card-actions>
              </v-row>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>

      <h2 class="display-1 text-center mt-6">
        {{ $vuetify.lang.t('$vuetify.tour.faq') }}
      </h2>

      <v-expansion-panels focusable>
        <v-expansion-panel
          v-for="(item, i) in faq"
          :key="i"
        >
          <v-expansion-panel-header>
            {{ $vuetify.lang.t(item.q) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content
          >
            <div v-html="$vuetify.lang.t(item.a)" class="mt-4"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
import { homeCards, homeSlider } from '../scripts/images';

export default {
  name: 'DestinationsEgypt',
  data: () => ({
    parallaxImg: homeSlider.egypt,
    cards: [
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '1397',
        day: 11,
        night: 10,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '1450',
        day: 8,
        night: 7,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '1395',
        day: 9,
        night: 8,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '1125',
        day: 7,
        night: 7,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '1400',
        day: 8,
        night: 7,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '954',
        day: 12,
        night: 11,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '1397',
        day: 11,
        night: 10,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '1450',
        day: 8,
        night: 7,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '1395',
        day: 9,
        night: 8,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '1125',
        day: 7,
        night: 7,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '1400',
        day: 8,
        night: 7,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
        price: '954',
        day: 12,
        night: 11,
        img: homeCards.egypt,
        link: '/destinations/egypt/egypt-and-marsa-alam',
      },
    ],
    faq: [
      {
        q: '$vuetify.faqEgypt.q1.q',
        a: '$vuetify.faqEgypt.q1.a',
      },
      {
        q: '$vuetify.faqEgypt.q2.q',
        a: '$vuetify.faqEgypt.q2.a',
      },
      {
        q: '$vuetify.faqEgypt.q3.q',
        a: '$vuetify.faqEgypt.q3.a',
      },
      {
        q: '$vuetify.faqEgypt.q4.q',
        a: '$vuetify.faqEgypt.q4.a',
      },
      {
        q: '$vuetify.faqEgypt.q5.q',
        a: '$vuetify.faqEgypt.q5.a',
      },
      {
        q: '$vuetify.faqEgypt.q6.q',
        a: '$vuetify.faqEgypt.q6.a',
      },
      {
        q: '$vuetify.faqEgypt.q7.q',
        a: '$vuetify.faqEgypt.q7.a',
      },
      {
        q: '$vuetify.faqEgypt.q8.q',
        a: '$vuetify.faqEgypt.q8.a',
      },
      {
        q: '$vuetify.faqEgypt.q9.q',
        a: '$vuetify.faqEgypt.q9.a',
      },
      {
        q: '$vuetify.faqEgypt.q10.q',
        a: '$vuetify.faqEgypt.q10.a',
      },
      {
        q: '$vuetify.faqEgypt.q11.q',
        a: '$vuetify.faqEgypt.q11.a',
      },
      {
        q: '$vuetify.faqEgypt.q12.q',
        a: '$vuetify.faqEgypt.q12.a',
      },
    ],
  }),
};
</script>

<style lang="scss">

</style>
